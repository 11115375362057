/*!
* lazyYT (lazy load YouTube videos)
* v1.2.1 - 2015-09-04
* (CC) This work is licensed under a Creative Commons Attribution-ShareAlike 4.0 International License.
* http://creativecommons.org/licenses/by-sa/4.0/
* Contributors: https://github.com/tylerpearson/lazyYT/graphs/contributors || https://github.com/daugilas/lazyYT/graphs/contributors
*
* Usage: <div class="lazyYT" data-youtube-id="laknj093n" data-parameters="rel=0">loading...</div>
*/

;(function($){function setUp($el, settings) {
    var width = $el.data("width"),
        height = $el.data("height"),
        title = $el.attr("title") || $el.data("title"),
        display_title = $el.data("display-title"),
        best_img = $el.data("best-image"),
        check_for_best_img = best_img ? null : $el.data("check-for-best-image"),
        ratio = $el.data("ratio") ? $el.data("ratio") : settings.default_ratio,
        display_duration = $el.data("display-duration"),
        id = $el.data("youtube-id"),
        padding_bottom,
        innerHtml = [],
        $thumb,
        thumb_img,
        loading_text = $el.text() ? $el.text() : settings.loading_text,
        youtube_parameters = $el.data("parameters") || "";
    ratio = ratio.split(":");
    youtube_parameters += "&" + settings.youtube_parameters;
    if (typeof display_title != "boolean") display_title = settings.display_title;
    if (typeof display_duration != "boolean") display_duration = settings.display_duration;
    if (typeof width === "number" && typeof height === "number") {
        $el.width(width);
        padding_bottom = height + "px";
    } else if (typeof width === "number") {
        $el.width(width);
        padding_bottom = (width * ratio[1]) / ratio[0] + "px";
    } else {
        width = $el.width();
        if (width == 0) width = $el.parent().width();
        padding_bottom = (ratio[1] / ratio[0]) * 100 + "%";
    }
    innerHtml.push('<div class="ytp-thumbnail b-lazy">');
    innerHtml.push('<button class="ytp-large-play-button ytp-button" tabindex="0" aria-live="assertive"');
    if (width <= 320) innerHtml.push(' style="transform: scale(0.61);"');
    else if (width <= 640) innerHtml.push(' style="transform: scale(0.85);"');
    if (display_title && title) innerHtml.push(' aria-label="', title, '"');
    innerHtml.push(">");
    innerHtml.push('<svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">');
    innerHtml.push(
        '<path class="ytp-large-play-button-bg" d="m .66,37.62 c 0,0 .66,4.70 2.70,6.77 2.58,2.71 5.98,2.63 7.49,2.91 5.43,.52 23.10,.68 23.12,.68 .00,-1.3e-5 14.29,-0.02 23.81,-0.71 1.32,-0.15 4.22,-0.17 6.81,-2.89 2.03,-2.07 2.70,-6.77 2.70,-6.77 0,0 .67,-5.52 .67,-11.04 l 0,-5.17 c 0,-5.52 -0.67,-11.04 -0.67,-11.04 0,0 -0.66,-4.70 -2.70,-6.77 C 62.03,.86 59.13,.84 57.80,.69 48.28,0 34.00,0 34.00,0 33.97,0 19.69,0 10.18,.69 8.85,.84 5.95,.86 3.36,3.58 1.32,5.65 .66,10.35 .66,10.35 c 0,0 -0.55,4.50 -0.66,9.45 l 0,8.36 c .10,4.94 .66,9.45 .66,9.45 z" fill="#1f1f1e" fill-opacity="0.9"></path>'
    );
    innerHtml.push('<path d="m 26.96,13.67 18.37,9.62 -18.37,9.55 -0.00,-19.17 z" fill="#fff"></path>');
    innerHtml.push('<path d="M 45.02,23.46 45.32,23.28 26.96,13.67 43.32,24.34 45.02,23.46 z" fill="#ccc"></path>');
    innerHtml.push("</svg>");
    innerHtml.push("</button>");
    if (display_duration) innerHtml.push('<span class="video-time" style="display:none;"></span>');
    innerHtml.push("</div>");
    if (display_title) {
        innerHtml.push('<div class="ytp-gradient-top"></div>');
        innerHtml.push('<div class="ytp-chrome-top">');
        innerHtml.push('<div class="ytp-title">');
        innerHtml.push('<div class="ytp-title-text">');
        innerHtml.push(
            '<a id="lazyYT-title-',
            id,
            '" class="ytp-title-link" tabindex="0" target="_blank" data-sessionlink="feature=player-title" href="https://www.youtube.com/watch?v=',
            id,
            '">'
        );
        innerHtml.push(title ? title : loading_text);
        innerHtml.push("</a>");
        innerHtml.push("</div>");
        innerHtml.push("</div>");
        innerHtml.push("</div>");
    }
    $el.css({ "padding-bottom": padding_bottom }).html(innerHtml.join(""));
    if (best_img) thumb_img = best_img;
    else if (width > 640) thumb_img = "hqdefault.jpg";
    else if (width > 480) thumb_img = "sddefault.jpg";
    else if (width > 320) thumb_img = "hqdefault.jpg";
    else if (width > 120) thumb_img = "mqdefault.jpg";
    else if (width == 0) thumb_img = "hqdefault.jpg";
    else thumb_img = "default.jpg";
    $thumb = $el
        .find(".ytp-thumbnail")
        .attr("data-src", ["https://img.youtube.com/vi/", id, "/", thumb_img].join(""))
        .addClass("lazyYT-image-loaded")
        .on("click", function (e) {
            e.preventDefault();
            if (!$el.hasClass("lazyYT-video-loaded") && $thumb.hasClass("lazyYT-image-loaded")) {
                $el.html(
                    '<iframe title="' +
                        title +
                        '" src="//www.youtube.com/embed/' +
                        id +
                        "?" +
                        youtube_parameters +
                        '&autoplay=1" frameborder="0" allowfullscreen></iframe>'
                ).addClass(settings.video_loaded_class);
                if (typeof settings.callback == "function") settings.callback.call($el);
            }
        });
}
function parseDuration(PT, settings) {
    var output = [];
    var durationInSec = 0;
    var matches = PT.match(/P(?:(\d*)Y)?(?:(\d*)M)?(?:(\d*)W)?(?:(\d*)D)?T(?:(\d*)H)?(?:(\d*)M)?(?:(\d*)S)?/i);
    var parts = [
        { pos: 1, multiplier: 86400 * 365 },
        { pos: 2, multiplier: 86400 * 30 },
        { pos: 3, multiplier: 604800 },
        { pos: 4, multiplier: 86400 },
        { pos: 5, multiplier: 3600 },
        { pos: 6, multiplier: 60 },
        { pos: 7, multiplier: 1 },
    ];
    for (var i = 0; i < parts.length; i++)
        if (typeof matches[parts[i].pos] != "undefined")
            durationInSec += parseInt(matches[parts[i].pos]) * parts[i].multiplier;
    if (durationInSec > 3599) {
        output.push(parseInt(durationInSec / 3600));
        durationInSec %= 3600;
    }
    output.push(("0" + parseInt(durationInSec / 60)).slice(-2));
    output.push(("0" + (durationInSec % 60)).slice(-2));
    return output.join(":");
}$.fn.lazyYT=function(yt_api_key,newSettings){var defaultSettings={yt_api_key:yt_api_key,youtube_parameters:"rel=0",loading_text:"Loading...",display_title:true,
default_ratio:"16:9",display_duration:false,callback:null,video_loaded_class:"lazyYT-video-loaded",container_class:"lazyYT-container"};var settings=$.extend(defaultSettings,newSettings);return this.each(function(){var $el=$(this).addClass(settings.container_class);setUp($el,settings)})}})(jQuery);

(function($) {

    $('article.medium-post, article.tile-post')
    	.on('mouseenter', '.post-inner', function(){
    		$(this).parents('article').addClass('hover');
    	})
    	.on('mouseleave', '.post-inner', function(){
    		$(this).parents('article').removeClass('hover');
    	})
	    .on('click', '.post-content', function(){
	        window.location.href = $(this).parents('article').find('header a').attr('href');
	    });

    $('header.hero-panel')
        .on('mouseenter', '.panel-inner', function(){
            $(this).parents('header').addClass('hover');
        })
        .on('mouseleave', '.panel-inner', function(){
            $(this).parents('header').removeClass('hover');
        })
        .on('click', '.post-excerpt', function(){
            window.location.href = $(this).parents('.panel-inner').find('.post-title a').attr('href');
        });

    // youtube placeholders
    if(typeof lazyYT_api_key !== 'undefined') {
        $('.lazyYT').lazyYT(lazyYT_api_key);
    }

    $('.tab-link').click(function(e){
        $('.tab-section' + $(this).attr('href')).show().siblings('.tab-section').hide();
        $('.tab-link').removeClass('active').filter($(this)).addClass('active');
        return false;
    });

    var $certs = $('#cert .cert-event');

    $('#cert-select').change(function(){
        var selector = $(this).find('option:selected').val();
        $certs.each(function(index,el){
            var $el = $(el), hide = !$el.is(selector);
            $el.toggleClass('hide-cert', hide)
        });

        if ($('#cert').hasClass('show-only-one')) {
            $certs.not('.hide-cert').filter(':gt(0)').addClass('hide-cert');
        }

        $('.dropdown-tabs a[data-selector="' + selector + '"]').parents('li').addClass('active').siblings().removeClass('active');

    }).val($('#cert').data('selector')).change();

    $('.dropdown-tabs').on('click', 'a', function(){
        var $el = $(this),
            selector = $el.data('selector');

        $('#cert-select').val(selector).change();
        $el.parents('li').addClass('active').siblings().removeClass('active');
    });

    var options = {
        root: null, // relative to document viewport
        rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0.5 // visible amount of item shown in relation to root
    };

    var postFeatureDiv = $(".post-content--feature");
    var relatedPostsDiv = $(".yarpp-related");
    var observer, h;

    function getScrollPercent() {
        var h = document.documentElement,
            b = document.body;
        return Math.round((h['scrollTop']||b['scrollTop']) / ((h['scrollHeight']||b['scrollHeight']) - h.clientHeight) * 100);
    }

    function onChange(changes, observer) {
        var firing = false;
        changes.forEach(function(change) {
            if (firing) {
                return;
            }
            firing = true;
            if (change.intersectionRatio >= 1) {
                var minPercentage = 15;
                var maxPercentage = 60;

                var scroll = getScrollPercent();

                if (relatedPostsDiv.length < 2 && scroll >= minPercentage && scroll < maxPercentage) {
                    var h = postFeatureDiv.find(":header");
                    // detach observers, mission complete
                    for (var i = 0; i < h.length; i++) {
                        observer.unobserve($(h[i])[0]);
                        firing = false;
                    }
                    var posts = relatedPostsDiv.clone().addClass("smaller in-content p2");
                    $(change.target).before(posts);

                }
            }
        });
    }

    if ('IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
        relatedPostsDiv.length > 0
    ) {
        observer = new IntersectionObserver(onChange, options);
        h = postFeatureDiv.find(":header");
        for (var i = 0; i < h.length; i++) {
            observer.observe($(h[i])[0]);
        }
    }

    // Show backup for hemp ads for sources that are holier-than-thou
    var windowSearch = window.location.search;
    if (windowSearch.match(/[\?&]utm_source=(facebook|fb|instagram|ig)(&|$)/) !== null || windowSearch.match(/[\?&]medium=(paidsocial)(&|$)/) !== null) {
        // .contains-hemp elements are hidden via JS in the onnit theme JS.
        $('.contains-hemp-fallback').removeClass('hide');
    }

    /***
     * Onclick and Keyup are both necessary for ADA
     */
    $(".social-button.share").click(function(e) {
        e.preventDefault();
        var windowFeatures = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600";
        window.open($(this).prop('href'), '_blank', windowFeatures);
        return false;
    });

    $(".social-button.share").keyup(function(e) {
        if (e.keyCode(13)) {
            $(".social-button.share").click();
        }
    });

})(jQuery);
